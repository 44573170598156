import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Urunler } from "./components/Urunler";
import { Login } from "./components/Login";
import { Register } from "./components/Register";
import { Productdetails } from "./components/Productdetails";



const AppRoutes = [

    {
        index: true,
        element: <Home />
    },
    {
        path: '/home',
        element: <Home />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/Urunler',
        element: <Urunler />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/register',
        element: <Register />
    },
    {
        path: '/productdetails/:productId',
        element: <Productdetails />
    }
];

export default AppRoutes;
