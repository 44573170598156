/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import './Login.css';

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);

        this.state = {
            mail: '',
            password: '',
            customers: [],
        };
    }

    componentDidMount() {
        this.customerData();
    }

    handleInputChange = (event, field) => {
        this.setState({ [field]: event.target.value });
    };

    handleLogin = () => {
        const { mail, password, customers } = this.state;

        // Burada gerçek giriş işlemleri yapılmalıdır.
        // Örneğin, bir API çağrısı kullanılabilir.

        // Örnek kontrol: Kullanıcı adı ve şifre boş olmamalıdır.
        let loginSuccess = false;
        customers.forEach(customer => {
            if (customer.email.toString() === mail && customer.password === password) {
                loginSuccess = true;
                // Giriş başarılı olduğunda yapılması gereken işlemler buraya eklenir.
            }
        });

        if (loginSuccess) {
            alert('Giriş Başarılı');
            window.location.href = "/Home";
            // Giriş başarılı olduğunda yapılması gereken işlemler buraya eklenir.
        } else {
            alert('Giriş Başarısız!');
        }

    };

    render() {
        const { mail, password } = this.state;

        return (
            <body id="login-body">
                <section id="login-section">
                    <div className="login-form">
                        <div className="login-text">
                            <h3>GİRİŞ</h3>
                            <p>Giriş yapınız veya hesap oluşturunuz</p>
                        </div>
                        <div className="mail">
                            <input
                                type="text"
                                placeholder="E-Posta"
                                value={mail}
                                onChange={(e) => this.handleInputChange(e, 'mail')}
                            />
                        </div>
                        <div className="password">
                            <input
                                type="password"
                                placeholder="Şifre"
                                value={password}
                                onChange={(e) => this.handleInputChange(e, 'password')}
                            />
                        </div>
                        <div className="login-button">
                            <button onClick={this.handleLogin}>Giriş Yap</button>
                        </div>
                        <div className="create-account">
                            <p>Hesabınız yok mu?<a href="/register"> Kayıt Olun</a></p>
                        </div>
                    </div>
                </section>
            </body>
        );
    }

    async customerData() {
        try {
            const response = await fetch('customer');
            const data = await response.json();
            this.setState({ customers: data, loading: false });
        } catch (error) {
            console.error('API isteği başarısız:', error);
        }
    }
}