/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import './Register.css';

export class Register extends Component {
    static displayName = Register.name;

    constructor(props) {
        super(props);

        this.state = {
            mail: '',
            password: '',
            name: '',
            surname: ''
        };
    }

    render() {
        const { mail, password, name, surname } = this.state;


        return (
            <body id="register-body">
                <section id="register-section">
                    <div className="register-form">
                        <div className="register-text">
                            <h3>KAYIT OL</h3>
                            <p>Hesap oluşturunuz veya giriş yapınız</p>
                        </div>
                        <div className="mail">
                            <input
                                type="text"
                                placeholder="E-Posta"
                                value={mail}
                                onChange={(e) => this.handleInputChange(e, 'mail')}
                            />
                        </div>
                        <div className="password">
                            <input
                                type="password"
                                placeholder="Şifre"
                                value={password}
                                onChange={(e) => this.handleInputChange(e, 'password')}
                            />
                        </div>
                        <div className="name">
                            <input
                                type="text"
                                placeholder="İsim"
                                value={name}
                                onChange={(e) => this.handleInputChange(e, 'name')}
                            />
                        </div>
                        <div className="surname">
                            <input
                                type="text"
                                placeholder="Soyisim"
                                value={surname}
                                onChange={(e) => this.handleInputChange(e, 'surname')}
                            />
                        </div>
                        <div className="register-button">
                            <button onClick={this.handleLogin}>Kayıt Ol</button>
                        </div>
                        <div className="create-account">
                            <p>Hesabınız zaten var mı?<a href="/login"> Giriş Olun</a></p>
                        </div>
                    </div>
                </section>
            </body>
        );
    }
}