import React, { Component, lazy } from 'react';
import Button from 'react-bootstrap/Button';
import './Home.css';


export class Home extends Component {
    static displayName = Home.name;

    getProduct = () => {
        window.location.href = "/Urunler";
    }
    render() {
        return (
            <body>
                <div className="body-home">
                    <section id="slayt1-section">
                        <div className="slayt-1" loading={lazy}>
                            {/*<img src="/ProductPhoto/çini2.jpg" />*/}
                        </div>
                    </section>
                    <section id="product-section">
                        <div className=" image-container">
                            <section className="product-section">
                                <div className="product" >
                                    <img src="/ProductPhoto/cini1.jpg" loading={lazy} />
                                    <p>3'Lü Nar</p>
                                    <p>150₺</p>
                                </div>
                                {/*<div className="product product-background" >*/}
                                {/*    <div>*/}
                                {/*        <img src="/ProductPhoto/cini1.jpg" />*/}
                                {/*        <p>DENEME</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="product" >
                                    <img src="/ProductPhoto/cini1.jpg" loading={lazy} />
                                    <p>3'Lü Nar</p>
                                    <p>150₺</p>
                                </div>
                                <div className="product" >
                                    <img src="/ProductPhoto/cini1.jpg" loading={lazy} />
                                    <p>3'Lü Nar</p>
                                    <p>150₺</p>
                                </div>
                                <div className="product" >
                                    <img src="/ProductPhoto/cini1.jpg" loading={lazy} />
                                    <p>3'Lü Nar</p>
                                    <p>150₺</p>
                                </div>
                                <div className="all-product-button">
                                    <Button variant="outline-secondary" onClick={this.getProduct}>Tüm Ürünler</Button>{' '}
                                </div>
                            </section>
                            <div className="slayt-2" >
                                {/*<img src="/ProductPhoto/çini3.jpg" loading={lazy} />*/}
                            </div>
                        </div>
                    </section>
                </div>
            </body>
        );
    }
}
