/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './Urunler.css';


export class Urunler extends Component {
    static displayName = Urunler.name;
        
    constructor(props) {
        super(props);
        this.state = { products: [], loading: true };
        this.addToCartHandler = this.addToCartHandler.bind(this);
        this.productDetail = this.productDetail.bind(this);
        this.productId = this.props.productDetailId;
    }

    componentDidMount() {
        this.populateWeatherData();
    }

    static renderForecastsTable(products, addToCartHandler, productDetail, city, handleProductPhotoClick) {

        return (
            <body>
                <div>
                    <section id="product">   {/*Product-section*/}
                        <div className="product-data">
                            {products.map(product =>
                                //<p>{product.name}</p>
                                //<img src={`data:image/jpeg;base64,${product.photo}`} />
                                <div key={product.id} className="product-list">
                                    <div className="product-photo" onClick={() => navigation.navigate(`/Productdetails/${product.id}`, { id: product.id })}>
                                        <img src={`data:image/jpeg;base64,${product.photo}`} />
                                        {/*<p>DENEME</p>*/}
                                    </div>
                                    <div className="product-name">
                                        <p>{product.name}</p>
                                    </div>
                                    <div className="product-price">
                                        <p>{product.price} ₺</p>
                                    </div>
                                    <div className="add-to-cart">
                                        <Button variant="outline-secondary" onClick={() => addToCartHandler(product)}>Sepete Ekle</Button>{' '}
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                </div>
            </body>
        );
    }

    render() {
        const { city } = this.props;
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Urunler.renderForecastsTable(this.state.product, this.addToCartHandler, this.productDetail, city, this.handleProductPhotoClick);

        return (
            <body className="product-body">
                <div>
                    <section id="product-section">   {/*Product-section*/}
                        <div className= "contents">
                            {contents}
                        </div>
                    </section>
                </div>
            </body>
        );
    }


    // Sepete ekleme işlemlerini gerçekleştirin
    addToCartHandler(product) {
        
        // Kullanıcı giriş yapmışsa sepete ekleme işlemini gerçekleştir
        if (false) {

        }
        //  Kullanıcı giriş yapmamışsa login ekranına gönder
        else {
            console.log("Kullanıcı giriş yapmadı.");
            window.location.href = "/Home";
        }
    }

    //  Product Detail Page
    productDetail(product) {
        console.log(product.id);
    }

    async populateWeatherData() {
        try {
            const response = await fetch('product/details');
            const data = await response.json();
            this.setState({ product: data, loading: false });
        } catch (error) {
            console.error('API isteği başarısız:', error);
        }
    }

}
