import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { Urunler } from './components/Urunler';
import { Productdetails } from './components/Productdetails';
import { Home } from './components/Home';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = { productId: 0 };
    }

    handleProductIdChange = (newProductId) => {
        this.setState({ productId: newProductId });
    };

    render() {
        return (
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, path, ...rest } = route;


                        if (path === '/Urunler') {
                            return (
                                <Route
                                    key={index}
                                    path={path}
                                    element={<Urunler productDetailId={this.state.productId} onProductIdChange={this.handleProductIdChange} />}
                                    {...rest}
                                />
                            );
                        }

                        if (path === '/Productdetails') {
                            return (
                                <Route
                                    key={index}
                                    path={"/productdetails/:productId"}
                                    element={<Productdetails getProductId={this.state.productId} />}
                                    {...rest}
                                />
                            );
                        }
                        //if (path === 'Productdetails')


                        return <Route key={index} path={path} element={element} {...rest} />;
                    })}
                </Routes>
            </Layout>
        );
    }
}
