/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import './Productdetails.css';


export class Productdetails extends Component {
    static displayName = Productdetails.name;


    constructor(props) {
        super(props);
        this.state = { products: [], loading: true, quantity: 1 };
        this.addToCartHandler = this.addToCartHandler.bind(this);
        this.toggleDetails = this.toggleDetails.bind(this);
        this.toggleShipping = this.toggleShipping.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.quantityPlus = this.quantityPlus.bind(this);
        this.quantityMinus = this.quantityMinus.bind(this);


        // products dizisini filtrele
        //this.id = this.state.getProductId
    }

    componentDidMount() {
        this.productData();
    }

    static renderForecastsTable(products, addToCartHandler, toggleDetails, toggleShipping, quantity, handleQuantityChange, quantityPlus, quantityMinus) {
        //console.log(products)
        //if (!products) {
        //    return <p>No product data available.</p>;
        //}

        return (
            <body className="product_details_body">
                    <div >
                        <section id="product_details_section">   {/*Product-section*/}
                        {products.map(product =>
                            <div key={product.id} className="product_details_data">
                                {/*{products?.map(product =>*/}
                                {/*    //<p>{product.name}</p>*/}
                                {/*    //<img src={`data:image/jpeg;base64,${product.photo}`} />*/}
                                {/*    <div key={product.id} className="product-list">*/}
                                {/*        */}{/*<div className="product-photo">*/}
                                {/*        */}{/*    <img src={`data:image/jpeg;base64,${product.photo}`} />*/}
                                {/*        */}{/*    */}{/*<p>DENEME</p>*/}
                                {/*        */}{/*</div>*/}
                                {/*        <div className="product-name">*/}
                                {/*            */}{/*<p>{product.name}</p>*/}
                                {/*        </div>*/}
                                {/*        <div className="product-price">*/}
                                {/*            */}{/*<p>{product.price} ₺</p>*/}
                                {/*        </div>*/}
                                {/*        <div className="add-to-cart">*/}
                                {/*            <Button variant="outline-secondary" onClick={() => addToCartHandler(product)}>Sepete Ekle</Button>{' '}*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                <div className="product_details">
                                    <div className="product_details_img">
                                        <img src={`data:image/jpeg;base64,${product.photo}`} />
                                        <p>
                                            {product.description}

                                        </p>
                                    </div>
                                    <div className="product_details_text">
                                        <p className="product_name">{product.name}</p>
                                        <p className="sku">SKU: {product.id}</p>
                                        <p className="product_price">{product.price}₺</p>
                                        <p className="quantity">Adet</p>
                                    </div>
                                    <div className="product_details_quantity">
                                        <input
                                            type="number"
                                            pattern="[0-9]*"
                                            data-hook="number-input-spinner-input"
                                            aria-label="Quantity"
                                            max={20}
                                            min={1}
                                            value={quantity}
                                            onChange={(e) => handleQuantityChange(e)}
                                            onInput={(e) => {
                                                if (e.target.value > 20) {
                                                    e.target.value = 20;
                                                    handleQuantityChange(e);
                                                }
                                            }}
                                        />
                                        <div className="product_details_quantity_counter">
                                            <div className="arrow-up">
                                                <span onClick={() => quantityPlus()} class="arrow up"></span>
                                            </div>
                                            <div className="arrow-down">
                                                <span onClick={() => quantityMinus()} class="arrow down"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product_details_quantity2">
                                        <div className="product_details_add_to_cart">
                                            <button onClick={this.handleAddToCart}>Sepete Ekle</button>
                                        </div>
                                        <div className="product_details_buy_now">
                                            <button onClick={this.handleBuyNow}>Hemen Satın Al</button>
                                        </div>
                                    </div>
                                    <div className="product_info">
                                        <p className="product_info_header" onClick={toggleDetails}>Ürün Bilgileri</p>
                                        <div className="product_info_text">
                                            <p>It is a long established fact that a reader will be distracted by the readable
                                                content of a page when looking at its layout. The point of using Lorem Ipsum
                                                is that it has a more-or-less normal distribution of letters, as opposed to
                                                using 'Content here, content here', making it look like readable English.
                                            </p>
                                        </div>
                                        <hr className="product_details_hr" />
                                    </div>
                                    <div className="product_shipping_info">
                                        <p className="product_shipping_info_header" onClick={toggleShipping}>Kargo Bilgileri</p>
                                        <div className="product_shipping_info_text">
                                            <p>It is a long established fact that a reader will be distracted by the readable
                                                content of a page when looking at its layout. The point of using Lorem Ipsum
                                                is that it has a more-or-less normal distribution of letters, as opposed to
                                                using 'Content here, content here', making it look like readable English.
                                            </p>
                                        </div>
                                        <hr className="product_details_hr" />
                                    </div>
                                </div>
                            </div>
                        )}
                        </section>
                    </div >

            </body >
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Productdetails.renderForecastsTable(
                this.state.product,
                this.addToCartHandler,
                this.toggleDetails,
                this.toggleShipping,
                this.state.quantity,
                this.handleQuantityChange,
                this.quantityPlus,
                this.quantityMinus
            );

        return (
            <body className="product-body">
                <div>
                    <section id="product-section">   {/*Product-section*/}
                        <div className="contents">
                            {contents}
                        </div>
                    </section>
                </div>
            </body>
        );
    }


    // Sepete ekleme işlemlerini gerçekleştirin
    addToCartHandler(product) {

        // Kullanıcı giriş yapmışsa sepete ekleme işlemini gerçekleştir
        if (false) {

        }
        //  Kullanıcı giriş yapmamışsa login ekranına gönder
        else {
            console.log("Kullanıcı giriş yapmadı.");
            window.location.href = "/Home";
        }
    }

    toggleDetails() {
        var detayElementi = document.querySelector(".product_info_text");
        var shippingElementi = document.querySelector(".product_shipping_info");
        var shippingVisible = document.querySelector(".product_shipping_info_text");


        detayElementi.classList.toggle("visible");

        if (detayElementi.classList.contains("visible")) {
            shippingElementi.classList.add("margin");
            if (shippingVisible.classList.contains("visible"))
                shippingVisible.classList.toggle("visible");
        }
        else {
            shippingElementi.classList.remove("margin");
        }
    }

    toggleShipping() {
        var shippingElementi = document.querySelector(".product_shipping_info_text");
        var shippingUnMargin = document.querySelector(".product_shipping_info");
        var detayElementi = document.querySelector(".product_info_text")

        shippingElementi.classList.toggle("visible");

        if (detayElementi.classList.contains("visible")) {
            detayElementi.classList.toggle("visible");
            shippingUnMargin.classList.remove("margin");
        }
    }

    

    handleQuantityChange(event) {
        this.setState({
            quantity: event.target.value,
        });
    }

    quantityPlus() {        
        if (this.state.quantity < 20) {
            this.setState((prevState) => ({
                quantity: prevState.quantity + 1,
            }));
        }
    }

    quantityMinus() {
        if (this.state.quantity > 1) {
            this.setState((prevState) => ({
                quantity: prevState.quantity - 1,
            }));
        }
    }

    async productData() {
        const productId = window.location.pathname.split('/').pop();
        //alert(productId)
        try {
            //('Product/id?id=' + 1)
            const response = await fetch(`product/id?id=${parseInt(productId)}`);
            //const response = await fetch(`product/details`);
            const data = await response.json();

            this.setState({ product: [data], loading: false });
        } catch (error) {
            console.error('API isteği başarısız:', error);
        }

    }

    

    // http://localhost:5255/Product/id?id=1
}
