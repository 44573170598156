/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
//import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
//import { Link } from 'react-router-dom';
//import Container from 'react-bootstrap/Container';
//import Nav from 'react-bootstrap/Nav';
//import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (

            <Navbar collapseOnSelect expand="lg" className="navbar-body2">
                <div className="logo">
                    <a href="/home"><h2>ÖzHatıra</h2></a>
                </div>
                <Container className="navbar-container" >
                    {/*<Navbar.Brand href="/home" className= "logo1">ÖzHatıra</Navbar.Brand>*/}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="navbar-element">
                            <Nav.Link href="/home">ANASAYFA</Nav.Link>
                            <Nav.Link href="#pricing">İLETİŞİM</Nav.Link>
                            <NavDropdown title="KATEGORİLER" id="collapsible-nav-dropdown">
                                {/*<NavDropdown.Item href="/counter">Action</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/Urunler">Tüm Ürünler</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    {/*<div className="logo">*/}
                    {/*    <h3>ÖzHatıra</h3>*/}
                    {/*</div>*/}
                    <Nav className="navbar-login">
                        <Nav.Link href="/Login">GİRİŞ</Nav.Link>
                        {/*<Nav.Link href="/Register">ÜYE OL</Nav.Link>*/}
                    </Nav>
                </Container>
            </Navbar>
        );
    }

}

