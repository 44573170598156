/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import './Footer.css';


export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);

        this.toggleFooter = this.toggleFooter.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleFooter() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <div className="footer-body">

                {/* SECTİON İSİM */}
                <section>
                    <div>
                        <a href="/home"><h1>ÖzHatıra</h1></a>
                    </div>
                </section>

                {/* SECTİON NAVBAR ELEMENT  */}
                <section>
                    <hr className="hr-1" />
                    <div className=" footer-navbar">
                        <Nav className="navbar-element-footer ">
                            <Nav.Link href="/Home">ANASAYFA</Nav.Link>
                            <Nav.Link href="/home">İLETİŞİM</Nav.Link>
                            <NavDropdown title="KATEGORİLER" id="collapsible-nav-dropdown">
                                {/*<NavDropdown.Item href="/counter">Action</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/Urunler">Tüm Ürünler</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </div>
                    {/*<hr className="hr-1" />*/}
                </section >

                {/* SECTİON SOCİAL MEDİA  */}
                <section className="mb-3 text-center">
                    <hr className="hr-1" />
                    <div>
                        <a className="btn btn-outline-dark btn-floating m-1"
                            href="/home"
                            role="button">
                            <FontAwesomeIcon icon={faInstagram} size="lg" />
                        </a>
                        <a className="btn btn-outline-dark btn-floating m-1"
                            href="/home"
                            role="button">
                            <FontAwesomeIcon icon={faFacebook} size="lg" />
                        </a>
                        <a className="btn btn-outline-dark btn-floating m-1"
                            href="/home"
                            role="button">
                            <FontAwesomeIcon icon={faInstagram} size="lg" />
                        </a>
                        <a className="btn btn-outline-dark btn-floating m-1"
                            href="/home"
                            role="button">
                            <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                        </a>
                    </div>
                </section>


                {/* COPYRIGHT */}
                <section>
                    <div className="text-center p-3 copyright">
                        © 2024 Copyright
                    </div>
                </section>
            </div>
        );
    }
}